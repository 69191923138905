import React from 'react';

function SoicalInformation({ formState, handleChange }) {
    return (
        <div className="Social-Information">
            <h2>Psychological Information</h2>
            <label>
                Current Life Stressors:
                <textarea name="CurrentLifeStressors" value={formState.CurrentLifeStressors} onChange={handleChange} rows="6"/>
            </label>
            <label>
                School History:
                <textarea name="SchoolHistory" value={formState.SchoolHistory} onChange={handleChange} rows="6" />
            </label>

            <label>
                Employment History:
                <textarea name="EmploymentHistory" value={formState.EmploymentHistory} onChange={handleChange} rows="6" />
            </label>
            <label>
                Military History:
                <textarea name="MilitaryHistory" value={formState.MilitaryHistory} onChange={handleChange} rows="6" />
            </label>
            <label>
                Legal History:
                <textarea name="LegalHistory" value={formState.LegalHistory} onChange={handleChange} rows="6" />
            </label>
            <label>
                School History:
                <textarea name="SchoolHistory" value={formState.SchoolHistory} onChange={handleChange} rows="6" />
            </label>
            <label>
                Family Structure:
                <textarea name="FamilyStructure" value={formState.FamilyStructure} onChange={handleChange} rows="4" />
            </label>
            <label>
                Relationship Satisfaction:
                <textarea name="RelationshipSatisfaction" value={formState.RelationshipSatisfaction} onChange={handleChange} rows="2" />
            </label>
            <label>
                History of Past Marriages:
                <textarea name="HistoryofPastMarriages" value={formState.HistoryofPastMarriages} onChange={handleChange} rows="2" />
            </label>
            <label>
                Sexuality:
                <textarea name="Sexuality" value={formState.Sexuality} onChange={handleChange} rows="2" />
            </label>
            <label>
                Cultural/Ethnic Background:
                <textarea name="CulturalBackground" value={formState.CulturalBackground} onChange={handleChange} rows="2" />
            </label>
            
        </div>
    );
}

export default SoicalInformation;
