import React from 'react';
import SupplementsTable from './SupplementsTable';

function BiologicalInformationForm({ formState, handleChange, tableRows,addTableRow,handleTableRowChange }) {
    return (
        <div className="Biological-Information">
            <h2>Biological Information</h2>
            <label>
                Prior Attempts To Correct Problems/Prior Psychiatric History:
                <textarea name="PriorPsychiatricHistory" value={formState.PriorPsychiatricHistory} onChange={handleChange} rows="6"/>
            </label>
            <label>
                Previous Diagnoses:
                <textarea name="PreviousDiagnoses" value={formState.PreviousDiagnoses} onChange={handleChange} rows="6" />
            </label>
            <label>
                Medications and supplements taken at the time of scan:
                <textarea name="MedicationSupplement" value={formState.MedicationSupplement} onChange={handleChange} rows="6" />
            </label>
            <h1>Health Evaluation Form</h1>
            <SupplementsTable
                tableRows={tableRows}
                addTableRow={addTableRow}
                handleTableRowChange={handleTableRowChange}/>


        </div>
    );
}

export default BiologicalInformationForm;
