import React, { useEffect, useState } from 'react';
import './App.css';
import DemographicsForm from './DemographicsForm';
import FacilityForm from './FacilityForm';
import PatientIdentificationForm from './PatientIdentification';
import formSections from './FormSections';
import ChiefComplaintForm from './ChiefComplaintForm';
import SupplementsTable from './SupplementsTable';
import BiologicalInformationForm from './BiologicalInformationForm';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { loginRequest } from './authConfig';

function App() {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const [tableRows, setTableRows] = useState([
    { dateStarted: '', dateEnded: '', supplement: '', effectiveness: '', sideEffects: '' }
  ]);

  const addTableRow = () => {
    setTableRows([...tableRows, { dateStarted: '', dateEnded: '', supplement: '', effectiveness: '', sideEffects: '' }]);
  };

  const handleTableRowChange = (index, event) => {
    const { name, value } = event.target;
    const newRows = [...tableRows];
    newRows[index][name] = value;
    setTableRows(newRows);
  };

  const [formState, setFormState] = useState({
    date: '',
    patientName: '',
    address: '',
    telephone: '',
    dob: '',
    email: '',
    patientId: '',
    cellPhone: '',
    gender: '',
    age: '',
    // Facility form Fields
    facilityName: 'Rezilir Health',
    facilityAddress: '16200 Hollywood FL 33021',
    historyDate: '',
    patientOutcomeManager: '',
    evaluationDate: '',
    clinicPhysician: '',
    PriorPsychiatricHistory: '',
    PreviousDiagnoses: '',
    MedicationSupplement: '',
    // BiologicalInformation Fields
    PriorPsychiatricHistory: '',
    PreviousDiagnoses: '',
    MedicationSupplement: '',
    //Chief Complaint Fields
    chiefComplaint: '',
    PresentingProblem: '',
    //DietaryExerciseInformation Fields
    DietaryExerviseInfomration: '',
    //Drug Alchol History
    DrugAlcoholHistory: '',
    //Family History Form
    BioMotherHistory: '',
    BioFatherHistory: '',
    SibilingsHistory: '',
    ChildrenHistory: '',
    //Medical History FormSections
    height: '',
    weight: '',
    waist: '',
    PrenatalBirthEvents: '',
    SeizureActivity: '',
    ExposureToxins: '',
    Allergies: '',
    HeadBrainTrauma: '',
    TestsLabs: '',
    //Mental Status Examinatino Fields
    Appearance: '',
    Attitude: '',
    Behavior: '',
    EyeContact: '',
    Speech: '',
    Orientation: '',
    Mood: '',
    Affect: '',
    ThoughtProcess: '',
    Worries: '',
    Delusions: '',
    SuicidalIdeation: '',
    Homicidal: '',
    Hallucinations: '',
    Illusions: '',
    DejaVu: '',
    AttentionSpan: '',
    //Patient Identification Fields
    Occupation: '',
    spouseDomesticPartnerName: '',
    spouseDomesticPartnerOccupation: '',
    race: '',
    religion: '',
    numChildren: '',
    martialStatus: '',
    school: '',
    grade: '',
    //PsychologicalInformation Fields
    SignificantLifeEvents: '',
    HistoryAbuse: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const currentSection = formSections[currentSectionIndex];
  const CurrentFormComponent = currentSection.component;

  useEffect(() => {
    if (formState.dob && formState.date) {
      const age = calculateAge(new Date(formState.dob), new Date(formState.date));
      setFormState(prevState => ({
        ...prevState,
        age: age
      }));
    }
  }, [formState.dob, formState.date]);

  const calculateAge = (dob, currentDate) => {
    const diff = currentDate - dob;
    const ageDate = new Date(diff);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    fetch('http://localhost:5000/create-document', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formState),
    })
      .then(response => response.json())
      .then(data => alert('Document created/updated successfully!'))
      .catch(error => console.error('Error:', error));
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`http://localhost:4000/search-patient?term=${searchTerm}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setSearchResults(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleSelectPatient = (patient) => {
    setSelectedPatient(patient);
    setFormState(patient);
    setSearchResults([]);
  };

  const handleCreateNewPatient = () => {
    setSelectedPatient(null);
    setFormState({
      date: '',
      patientName: '',
      address: '',
      telephone: '',
      dob: '',
      email: '',
      patientId: '',
      cellPhone: '',
      gender: '',
      age: '',
      // Facility form Fields
      facilityName: 'Rezilir Health',
      facilityAddress: '16200 Hollywood FL 33021',
      historyDate: '',
      patientOutcomeManager: '',
      evaluationDate: '',
      clinicPhysician: '',
      PriorPsychiatricHistory: '',
      PreviousDiagnoses: '',
      MedicationSupplement: '',
      // BiologicalInformation Fields
      PriorPsychiatricHistory: '',
      PreviousDiagnoses: '',
      MedicationSupplement: '',
      //Chief Complaint Fields
      chiefComplaint: '',
      PresentingProblem: '',
      //DietaryExerciseInformation Fields
      DietaryExerviseInfomration: '',
      //Drug Alchol History
      DrugAlcoholHistory: '',
      //Family History Form
      BioMotherHistory: '',
      BioFatherHistory: '',
      SibilingsHistory: '',
      ChildrenHistory: '',
      //Medical History FormSections
      height: '',
      weight: '',
      waist: '',
      PrenatalBirthEvents: '',
      SeizureActivity: '',
      ExposureToxins: '',
      Allergies: '',
      HeadBrainTrauma: '',
      TestsLabs: '',
      //Mental Status Examinatino Fields
      Appearance: '',
      Attitude: '',
      Behavior: '',
      EyeContact: '',
      Speech: '',
      Orientation: '',
      Mood: '',
      Affect: '',
      ThoughtProcess: '',
      Worries: '',
      Delusions: '',
      SuicidalIdeation: '',
      Homicidal: '',
      Hallucinations: '',
      Illusions: '',
      DejaVu: '',
      AttentionSpan: '',
      //Patient Identification Fields
      Occupation: '',
      spouseDomesticPartnerName: '',
      spouseDomesticPartnerOccupation: '',
      race: '',
      religion: '',
      numChildren: '',
      martialStatus: '',
      school: '',
      grade: '',
      //PsychologicalInformation Fields
      SignificantLifeEvents: '',
      HistoryAbuse: ''
    });
  };

  // Login handler
  const handleLogin = () => {
    instance.loginRedirect(loginRequest);
  };

  // Logout handler
  const handleLogout = () => {
    instance.logoutRedirect({ postLogoutRedirectUri: '/' });
  };

  if (!isAuthenticated) {
    return <button onClick={handleLogin}>Login</button>;
  }

  if (!selectedPatient) {
    return (
      <div className="App">
        <header>
          <h1>Search or Create Patient</h1>
        </header>
        <form onSubmit={handleSearchSubmit}>
          <input type="text" value={searchTerm} onChange={handleSearchChange} placeholder="Search by Name or Patient ID" />
          <button type="submit">Search</button>
        </form>
        <button onClick={handleCreateNewPatient}>Create New Patient</button>
        {searchResults.length > 0 && (
          <ul>
            {searchResults.map((patient) => (
              <li key={patient.patientId} onClick={() => handleSelectPatient(patient)}>
                {patient.patientName} - {patient.patientId}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }

  return (
    <div className="App">
      <header>
        <h1>{currentSection.name}</h1>
      </header>
      <form onSubmit={handleSubmit}>
        <CurrentFormComponent formState={formState} handleChange={handleChange} tableRows={tableRows} addTableRow={addTableRow} handleTableRowChange={handleTableRowChange} />
        <div className="navigation">
          {currentSectionIndex > 0 && (
            <button type="button" onClick={() => setCurrentSectionIndex(currentSectionIndex - 1)}>
              Previous: {formSections[currentSectionIndex - 1].name}
            </button>
          )}
          {currentSectionIndex < formSections.length - 1 && (
            <button type="button" onClick={() => setCurrentSectionIndex(currentSectionIndex + 1)}>
              Next: {formSections[currentSectionIndex + 1].name}
            </button>
          )}
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default App;
