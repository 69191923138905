import React from 'react';

function DrugAlcoholHistory({ formState, handleChange }) {
    return (
        <div className="Drug-Alcohol">
            <h2>Drug and Alchol History</h2>
            <label>
                Drug and Alchol History:
                <textarea name="DrugAlcoholHistory" value={formState.DrugAlcoholHistory} onChange={handleChange} rows="6"/>
            </label>
        </div>
    );
}

export default DrugAlcoholHistory;
