import React from 'react';

function PsychologicalInformation({ formState, handleChange }) {
    return (
        <div className="Psychological-Information">
            <h2>Psychological Information </h2>
            <label>
                Significant Life Events:
                <textarea name="SignificantLifeEvents" value={formState.SignificantLifeEvents} onChange={handleChange} rows="6"/>
            </label>
            <label>
                HistoryAbuse:
                <textarea name="HistoryAbuse" value={formState.HistoryAbuse} onChange={handleChange} rows="6" />
            </label>
        </div>
    );
}

export default PsychologicalInformation;
