import React from 'react';

function MentalStatusExamination({ formState, handleChange }) {
    return (
        <div className="Mental-Status-Examination">
            <h2>Mental Status Examination</h2>
            <label>
                Appearance:
                <textarea name="Appearance" value={formState.Appearance} onChange={handleChange} rows="2"/>
            </label>
            <label>
                Attitude:
                <textarea name="Attitude" value={formState.Attitude} onChange={handleChange} rows="2" />
            </label>
            <label>
                Behavior:
                <textarea name="Behavior" value={formState.Behavior} onChange={handleChange} rows="2" />
            </label>
            <label>
                Eye Contact:
                <textarea name="EyeContact" value={formState.EyeContact} onChange={handleChange} rows="2" />
            </label>
            <label>
                Speech:
                <textarea name="Speech" value={formState.Speech} onChange={handleChange} rows="2" />
            </label>
            <label>
                Orientation:
                <textarea name="Orientation" value={formState.Orientation} onChange={handleChange} rows="2" />
            </label>
            <label>
                Mood:
                <textarea name="Mood" value={formState.Mood} onChange={handleChange} rows="2" />
            </label>
            <label>
                Affect:
                <textarea name="Affect" value={formState.Affect} onChange={handleChange} rows="2" />
            </label>
            <label>
                Thought Process:
                <textarea name="ThoughtProcess" value={formState.ThoughtProcess} onChange={handleChange} rows="2" />
            </label>
            <label>
                Worries:
                <textarea name="Worries" value={formState.Worries} onChange={handleChange} rows="2" />
            </label>
            <label>
                Delusions:
                <textarea name="Delusions" value={formState.Delusions} onChange={handleChange} rows="2" />
            </label>
            <label>
                Suicidal ideation:
                <textarea name="SuicidalIdeation" value={formState.SuicidalIdeation} onChange={handleChange} rows="2" />
            </label>
            <label>
                Homicidal:
                <textarea name="Homicidal" value={formState.Homicidal} onChange={handleChange} rows="2" />
            </label>
            <label>
                Hallucinations:
                <textarea name="Hallucinations" value={formState.Hallucinations} onChange={handleChange} rows="2" />
            </label>
            <label>
                Illusions:
                <textarea name="Illusions" value={formState.Illusions} onChange={handleChange} rows="2" />
            </label>
            <label>
                DejaVu:
                <textarea name="DejaVu" value={formState.DejaVu} onChange={handleChange} rows="2" />
            </label>
            <label>
                Attention Span:
                <textarea name="AttentionSpan" value={formState.AttentionSpan} onChange={handleChange} rows="2" />
            </label>
            
            
        </div>
    );
}

export default MentalStatusExamination;
