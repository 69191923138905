import React from 'react';

function FacilityForm({ formState, handleChange }) {
    return (
        <div className="facility-section">
            <h2>Facility</h2>
            <label>
                Facility Name:
                <input type="text" name="facilityName" value={formState.facilityName} onChange={handleChange} />
            </label>
            <label>
                Facility Address:
                <input type="text" name="facilityAddress" value={formState.facilityAddress} onChange={handleChange} />
            </label>
            <label>
                History Date:
                <input type="date" name="historyDate" value={formState.historyDate} onChange={handleChange} />
            </label>
            <label>
                Patient Outcome Manager:
                <input type="text" name="patientOutcomeManager" value={formState.patientOutcomeManager} onChange={handleChange} />
            </label>
            <label>
                Evaluation Date:
                <input type="date" name="evaluationDate" value={formState.evaluationDate} onChange={handleChange} />
            </label>
            <label>
                Rezilir Clinic Physician:
                <input type="text" name="clinicPhysician" value={formState.clinicPhysician} onChange={handleChange} />
            </label>
        </div>
    );
}

export default FacilityForm;
