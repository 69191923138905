import React, { useEffect, useState } from 'react';

function DemographicsForm({formState,handleChange}){
  return (
    <div className="demographics-section">
      <h2>Demographics</h2>
        <div className="column">
          <label>
            Date:
            <input type="date" name="date" value={formState.date} onChange={handleChange} />
          </label>
          <label>
            Patient Name:
            <input type="text" name="patientName" value={formState.patientName} onChange={handleChange} />
          </label>
          <label>
            Address:
            <input type="text" name="address" value={formState.address} onChange={handleChange} />
          </label>
          <label>
            Telephone:
            <input type="tel" name="telephone" value={formState.telephone} onChange={handleChange} />
          </label>
          <label>
            Date of Birth:
            <input type="date" name="dob" value={formState.dob} onChange={handleChange} />
            Age: {formState.age}
          </label>
          <label>
            Email:
            <input type="email" name="email" value={formState.email} onChange={handleChange} />
          </label>
        </div>
        <div className="column">
          <label>
            Patient ID#:
            <input type="text" name="patientId" value={formState.patientId} onChange={handleChange} />
          </label>
          <label>
            Cell Phone:
            <input type="tel" name="cellPhone" value={formState.cellPhone} align="right" onChange={handleChange} />
          </label>
          <label>
            Gender:
            <select name="gender" value={formState.gender} onChange={handleChange}>
              <option value="">Select</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </label>
        </div>
    </div>
  );
}

export default DemographicsForm;
