import DemographicsForm from './DemographicsForm';
import FacilityForm from './FacilityForm';
import PatientIdentificationForm from './PatientIdentification';
import ChiefComplaintForm from './ChiefComplaintForm';
import BiologicalInformationForm from './BiologicalInformationForm';
import MedicalHistory from './MedicalHistory';
import DietaryExerciseInformation from './DietaryExerciseInformation';
import DrugAlcoholHistory from './DrugAlchololHistory';
import SleepBehavior from './SleepBehavior'
import FamilyHistory from './FamilyHistory';
import PsychologicalInformation from './PsychologicalInformation';
import SoicalInformation from './SocialInformation';
import MentalStatusExamination from './MentalStatusExamination';
// Import other form sections as needed

const formSections = [
    { name: 'Demographics', component: DemographicsForm },
    { name: 'Facility', component: FacilityForm },
    { name: 'Patient Identification', component: PatientIdentificationForm},
    { name: 'Chief Complaint', component: ChiefComplaintForm},
    { name: 'Biological Information', component: BiologicalInformationForm},
    { name: 'Medical History', component:MedicalHistory},
    { name: 'Dietary Exervise Information', component:DietaryExerciseInformation},
    { name: 'Drug Alcohol History', component:DrugAlcoholHistory },
    { name: 'Sleep Behavior', component:SleepBehavior},
    { name: 'Family History', component:FamilyHistory},
    { name: 'Psychological Information', component:PsychologicalInformation},
    { name: 'Social Information', component:SoicalInformation },
    { name: 'Mental Status Examination', component:MentalStatusExamination}


    // Add other sections here
];

export default formSections;
