import React from 'react';

function SleepBehavior({ formState, handleChange }) {
    return (
        <div className="Sleep-Behavior">
            <h2>Sleep Behaviour</h2>
            <label>
                Sleep Behaviour:
                <textarea name="SleepBehavior" value={formState.SleepBehavior} onChange={handleChange} rows="6"/>
            </label>
        </div>
    );
}

export default SleepBehavior;
