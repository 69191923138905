import React from 'react';

function DietaryExerciseInformation({ formState, handleChange }) {
    return (
        <div className="Dietary-Exercise-Infromation">
            <h2>Dietary/Exercise Information</h2>
            <label>
                Dietary/Exercise Information:
                <textarea name="DietaryExerciseInformation" value={formState.DietaryExerviseInfomration} onChange={handleChange} rows="6"/>
            </label>
        </div>
    );
}

export default DietaryExerciseInformation;
