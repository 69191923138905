import React from 'react';

function FamilyHistory({ formState, handleChange }) {
    return (
        <div className="Family-History">
            <h2>Family History </h2>
            <label>
                Biological Mother's History:
                <textarea name="BioMotherHistory" value={formState.BioMotherHistory} onChange={handleChange} rows="6" />
            </label>
            <label>
                Biological Fathers's History:
                <textarea name="BioFatherHistory" value={formState.BioFatherHistory} onChange={handleChange} rows="6" />
            </label>
            <label>
                Siblings:
                <textarea name="SibilingsHistory" value={formState.SibilingsHistory} onChange={handleChange} rows="3" />
            </label>
            <label>
                Children:
                <textarea name="ChildrensHistory" value={formState.ChildrenHistory} onChange={handleChange} rows="6" />
            </label>
        </div>
    );
}

export default FamilyHistory;
