export const msalConfig = {
    auth: {
        clientId: '7cfa7198-89e2-438a-82d5-fab0ebb8fc9b', // This is the Application (client) ID from Azure
        authority: 'https://login.microsoftonline.com/c1e017e3-4bc2-461a-b9f9-cb85e9094152', // This can also be a specific authority if required
        redirectUri: 'https://consult.rezilir.ai'
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your tokens are stored
        storeAuthStateInCookie: true, // Set to true if you need to support IE or Edge
    }
};

export const loginRequest = {
    scopes: ["openid", "profile", "User.Read"] // Replace with appropriate scopes
};