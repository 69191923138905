import React from 'react';

function ChiefComplaintForm({ formState, handleChange }) {
    return (
        <div className="Chief-Complaint">
            <h2>Chief Complaint</h2>
            <label>
                Chìef Complaint/Patient Goals for Evaluation:
                <textarea name="ChiefComplaint" value={formState.chiefComplaint} onChange={handleChange} rows="6"/>
            </label>
            <label>
                Presenting problem/primary symptoms:
                <textarea name="PresentingProblem" value={formState.PresentingProblem} onChange={handleChange} rows="12" />
            </label>
            

        </div>
    );
}

export default ChiefComplaintForm;
