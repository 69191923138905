import React from 'react';

function MedicalHistory({ formState, handleChange }) {
    return (
        <div className="Medical-History">
            <h2>Medical History</h2>
            <label>
                Height:
                <input type="text" name="height" value={formState.height} onChange={handleChange} />
            </label>
            <label>
                Weight:
                <input type="text" name="weight" value={formState.weight} onChange={handleChange} />
            </label>
            <label>
                Waist:
                <input type="text" name="waist" value={formState.waist} onChange={handleChange} />
            </label>

            // Insert Table Here laters

            <h2>Past Medical History</h2>
            <label>
                Prental and Birth Events:
                <input type="text" name="PrenatalBirtEvents" value={formState.PrenatalBirthEvents} onChange={handleChange} />
            </label>
            <label>
                Seizure Activity:
                <input type="text" name="SeizureActivity" value={formState.SeizureActivity} onChange={handleChange} />
            </label>
            <label>
                Exposure to Toxins:
                <input type="text" name="ExposureToxins" value={formState.ExposureToxins} onChange={handleChange} />
            </label>
            <label>
                Allergies/Drug intolerances:
                <input type="text" name="Allergies" value={formState.Allergies} onChange={handleChange} />
            </label>
            <label>
                Head/Brain Trauma:
                <input type="text" name="HeadBrainTrauma" value={formState.HeadBrainTrauma} onChange={handleChange} />
            </label>
            <label>
                Tests and Labs:
                <input type="text" name="TestsLabs" value={formState.TestsLabs} onChange={handleChange} />
            </label>
            
        </div>
    );
}

export default MedicalHistory;
