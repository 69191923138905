import React from 'react';

function PatientIdentificationForm({ formState, handleChange }) {
    return (
        <div className="patient-identification">
            <h2>Patient Identification</h2>
            <label>
                Occupation:
                <input type="text" name="Occupation" value={formState.Occupation} onChange={handleChange} />
            </label>
            <label>
                Spouse or domestic partner's name:
                <input type="text" name="spouseDomesticPartnerName" value={formState.spouseDomesticPartnerName} onChange={handleChange} />
            </label>
            <label>
                Spouse or domestic partner's occupation:
                <input type="date" name="spouseDomesticPartnerOccupation" value={formState.spouseDomesticPartnerOccupation} onChange={handleChange} />
            </label>
            <label>
                Race:
                <input type="text" name="race" value={formState.race} onChange={handleChange} />
            </label>
            <label>
                Religion:
                <input type="date" name="religion" value={formState.religion} onChange={handleChange} />
            </label>
            <label>
                Number of children:
                <input type="text" name="numChildren" value={formState.numChildren} onChange={handleChange} />
            </label>
            <label>
                Marital Status:
                <input type="text" name="martialStatus" value={formState.martialStatus} onChange={handleChange} />
            </label>
            <label>
                School:
                <input type="text" name="school" value={formState.school} onChange={handleChange} />
            </label>
            <label>
                Grade:
                <input type="text" name="grade" value={formState.grade} onChange={handleChange} />
            </label>

        </div>
    );
}

export default PatientIdentificationForm;
