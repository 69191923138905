import React from 'react';

function SupplementsTable({ tableRows, addTableRow, handleTableRowChange }) {
    return (
        <div>
            <table>
                <thead>
                    <tr>
                        <th>Date Started</th>
                        <th>Date Ended</th>
                        <th>Supplement Individual</th>
                        <th>Effectiveness</th>
                        <th>Side Effects</th>
                    </tr>
                </thead>
                <tbody>
                    {tableRows.map((row, index) => (
                        <tr key={index}>
                            <td><input type="date" name="dateStarted" value={row.dateStarted} onChange={(e) => handleTableRowChange(index, e)} /></td>
                            <td><input type="date" name="dateEnded" value={row.dateEnded} onChange={(e) => handleTableRowChange(index, e)} /></td>
                            <td><input type="text" name="supplement" value={row.supplement} onChange={(e) => handleTableRowChange(index, e)} /></td>
                            <td><input type="text" name="effectiveness" value={row.effectiveness} onChange={(e) => handleTableRowChange(index, e)} /></td>
                            <td><input type="text" name="sideEffects" value={row.sideEffects} onChange={(e) => handleTableRowChange(index, e)} /></td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <button type="button" onClick={addTableRow}>Add Row</button>
        </div>
    );
}

export default SupplementsTable;
